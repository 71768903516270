import _ from 'lodash';
import cn from 'classnames';
import React, { Component } from 'react';
// TODO: rangeslider repo is no longer active. Replace.
import Slider from 'react-rangeslider';

import CateringUtils from '../../modules/cateringUtils';

import 'react-rangeslider/lib/index.css';
import styles from './CostEstimator.module.postcss';
import mapFilterStyles from './MapFilters.module.postcss';

const meals = ['Entree', 'Side', 'Drink', 'Dessert'];

export default class CostEstimator extends Component {
  state = {
    meals: ['Side'],
    people: 60,
  }

  onSelectMeal(e) {
    let meals = this.state.meals;
    const meal = e.target.value;

    if (_.includes(this.state.meals, meal)) {
      meals = _.without(this.state.meals, meal);
    } else {
      meals = _.concat(this.state.meals, meal);
    }

    this.setState({ meals });
  }

  renderMealButton(id) {
    const classNames = cn(`ButtonSet ${mapFilterStyles.MapFilters_checkbox}`, {
      'ButtonSet--left': meals[0] === id,
      'ButtonSet--right': meals[meals.length - 1] === id,
      [mapFilterStyles.MapFilters_checkbox__checked]: _.includes(this.state.meals, id),
    });

    return (
      <label className={classNames} htmlFor={id} key={id}>
        <span>{_.capitalize(id)}</span>
        <input
          id={id}
          key={id}
          name="meal"
          onChange={this.onSelectMeal.bind(this)}
          type="checkbox"
          value={id}
        />
      </label>
    );
  }

  getEstimate() {
    const { low, high } = CateringUtils.calculateBudgetRange({
      food_options: this.state.meals,
      number_of_trucks: 1,
      number_of_people: this.state.people,
    });

    return `$${low} - $${high}`;
  }

  render() {
    const { title } = this.props;

    return (
      <section className={styles.CostEstimator + " PaddedSection"}>
        <div className="Container">
          <h3>{title ? title : "Catering cost estimator"}</h3>
          <div className={styles.CostEstimator_contents}>
            <div>
              <p>About how many people are you expecting?</p>
              <Slider
                labels={{ 50: '50', 500: '500' }}
                max={500}
                min={50}
                onChange={(people) => this.setState({ people })}
                step={10}
                value={this.state.people}
              />
            </div>
            <div>
              <p>What are you serving?</p>
              <div>
                {meals.map((id) => this.renderMealButton(id))}
              </div>
            </div>
            <div>
              <p>Cost estimate</p>
              <p className={styles.CostEstimate_estimate}>{this.getEstimate()}</p>
            </div>
          </div>
          <a href="/catering/register" className="Button Button--blue u-mt1">
            Get started with a quote!
          </a>
        </div>
      </section>
    );
  }
}
