import _ from 'lodash';

const minimumPerTruck = 750;
const maximumPerTruck = 1500;
const minimumEntreeFee = 750;
const minimumDessertFee = 500;
const perEntreeLow = 10;
const perEntreeHigh = 16;
const perSideLow = 4;
const perSideHigh = 7;
const perDessertLow = 4;
const perDessertHigh = 7;
const perDrinkLow = 1;
const perDrinkHigh = 3;

export const publicOccasions = [
  { value: 'Corporate Event', icon: 'corporate' },
  { value: 'Birthday Party', icon: 'birthday' },
  { value: 'School Event', icon: 'school' },
  { value: 'Community/Religious Event', icon: 'community' },
  { value: 'Music Festival', icon: 'music-fest'},
  { value: 'Store Opening', icon: 'store'},
  { value: 'Production Event', icon: 'production'},
  { value: 'Fundraiser', icon: 'fundraiser' },
  { value: 'Employee Appreciation', icon: 'employee'},
  { value: 'Large Scale Event', icon: 'large'},
  { value: 'Other* (if other please specify)', icon: 'community' }
];

export const privateOccasions = [
  { value: 'Corporate Event', icon: 'corporate' },
  { value: 'Birthday Party', icon: 'birthday' },
  { value: 'Private Party', icon: 'private' },
  { value: 'Graduation', icon: 'school' },
  { value: 'Wedding/Engagement', icon: 'wedding' },
  { value: 'Baby Shower', icon: 'birthday' },
  { value: 'School Event', icon: 'school' },
  { value: 'Community/Religious Event', icon: 'community' },
  { value: 'Music Festival', icon: 'music-fest'},
  { value: 'Store Opening', icon: 'store'},
  { value: 'Production Event', icon: 'production'},
  { value: 'Fundraiser', icon: 'fundraiser' },
  { value: 'Employee Appreciation', icon: 'employee'},
  { value: 'Large Scale Event', icon: 'large'},
  { value: 'Other* (if other please specify)', icon: 'community' }
];

const CateringUtils = {
  calculateBudgetRange(eventData) {
    const { food_options: options, number_of_trucks: trucks,
            number_of_people: people } = eventData;

    let totalLow = 0;
    let totalHigh = 0;

    if (_.includes(options, 'Entree')) {
      let entreeLow = people * perEntreeLow;
      entreeLow = Math.max(entreeLow, minimumEntreeFee);
      const entreeHigh = people * perEntreeHigh;
      totalLow += entreeLow;
      totalHigh += entreeHigh;
    }

    if (_.includes(options, 'Dessert')) {
      let dessertLow = people * perDessertLow;
      dessertLow = Math.max(dessertLow, minimumDessertFee);
      const dessertHigh = people * perDessertHigh;
      totalLow += dessertLow;
      totalHigh += dessertHigh;
    }

    if (_.includes(options, 'Side')) {
      const sideLow = people * perSideLow;
      const sideHigh = people * perSideHigh;
      totalLow += sideLow;
      totalHigh += sideHigh;
    }

    if (_.includes(options, 'Drink')) {
      const drinkLow = people * perDrinkLow;
      const drinkHigh = people * perDrinkHigh;
      totalLow += drinkLow;
      totalHigh += drinkHigh;
    }

    totalLow = Math.max(minimumPerTruck * trucks, totalLow);
    totalHigh = Math.max(maximumPerTruck * trucks, totalHigh);

    return {
      low: totalLow,
      high: totalHigh
    };
  },

  getIconForOccasion(occasion) {
    const allOccasions = publicOccasions.concat(privateOccasions);

    const foundOccasion = _.find(allOccasions, (occ) => {
      return occ.value === occasion;
    });

    if (foundOccasion) {
      return '/static/images/admin/catering/' + foundOccasion.icon + '.svg';
    } else {
      return '/static/images/admin/catering/community.svg';
    }
  },
};

export default CateringUtils;
